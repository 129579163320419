<template>
  <b-overlay
    :show="$apollo.loading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search startups"
          type="text"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Common -->
        <span class="d-flex justify-content-between">
          <span v-if="props.column.field == 'Managers'">
            <!-- <b-button
              size="sm"
              variant="outline-primary"
              :to="{name: 'view-programs-manager', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Manage</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              :to="{name: 'view-growth', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Growth</span>
            </b-button> -->
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-reviews', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Exercises</span>
            </b-button>
            <!-- <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'progress-milestone', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Milestones</span>
            </b-button> -->
            <!-- <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-feedbacks', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Feedbacks</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-requests', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Requests</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-emeets', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>E-Meets</span>
            </b-button> -->
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
            <b-button
              v-if="props.row.programs_applicantstable.users_customuser"
              size="sm"
              class="ml-50"
              variant="outline-primary"
              :to="{name: 'startup-manager', params: {id: props.row.programs_applicantstable.users_customuser.users_associationtables[0].id}}"
            >
              <span>View Details</span>
            </b-button>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
import { getUserData } from '@/utils/auth'

export default {
  components: {

    BFormInput,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      searchTerm: '',
      rounds: [
        { title: null },
      ],
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [

        {
          label: 'Name',
          field(row) {
            if (!row.programs_applicantstable) return '-'
            return row.programs_applicantstable.users_organizationtable.title || '-'
          },
        },
        {
          label: 'Type',
          tdClass: 'text-capitalize',
          field(row) {
            if (row.programs_applicantstable.users_customuser) return row.programs_applicantstable.users_customuser.role
            return row.programs_applicantstable.users_organizationtable.type
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for search input
            filterDropdownItems: ['Startup', 'Student', 'Mentor', 'Partner'],
          },
        },
        {
          label: 'Managers',
          field: 'Managers',
        },
      ],
      rows: [],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
        query startups_progress ($incubator_id: Int!){
          programs_startupparticipants(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
                type
              }
              users_customuser {
                full_name
                role
                users_associationtables(where: {incubator_id: {_eq: $incubator_id}}) {
                  id
                }
              }
            }
          }
        }`
      },
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update: data => data.programs_startupparticipants,
    },
  },
}
</script>
